import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/vanatu-team/vanatu-app-landing-page/src/layout/DefaultPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy policy`}</h1>
    <p>{`Last updated: May 23, 2022`}</p>
    <p>{`This privacy policy applies to the website `}<a parentName="p" {...{
        "href": "https://vanatu.app"
      }}>{`https://vanatu.app`}</a>{` and all other URLs and subdomains
that contain or end with "`}<a parentName="p" {...{
        "href": "https://vanatu.app%22"
      }}>{`https://vanatu.app"`}</a>{` (collectively, the "Website").`}</p>
    <h2>{`1. General information`}</h2>
    <p>{`The responsible party ("we", "us") within the meaning of the General Data Protection Regulation
and other national data protection laws of the member states as well as other data protection regulations is:`}</p>
    <p>{`relayts UG (haftungsbeschränkt)`}<br />{`
Beskidenstr. 18A`}<br />{`
14129 Berlin`}<br />{`
Germany`}</p>
    <p>{`+49 162 649 41 95`}<br />{`
`}<a parentName="p" {...{
        "href": "mailto:info@relayts.com"
      }}>{`info@relayts.com`}</a><br />{`
`}<a parentName="p" {...{
        "href": "http://www.relayts.com"
      }}>{`www.relayts.com`}</a></p>
    <h2>{`2. Provision of the website and creation of log files`}</h2>
    <p>{`Each time the website is accessed, data and information are automatically collected from the calling device.
The following data is collected in this process:`}</p>
    <ul>
      <li parentName="ul">{`IP address`}</li>
      <li parentName="ul">{`name of the website visited, file, date and time of the request`}</li>
      <li parentName="ul">{`information about the browser type and version used`}</li>
      <li parentName="ul">{`operating system`}</li>
      <li parentName="ul">{`previously visited website (referrer URL)`}</li>
      <li parentName="ul">{`requesting provider`}</li>
      <li parentName="ul">{`amount of data transferred`}</li>
    </ul>
    <p>{`This data is stored in the log files of our system. This data is not stored together with other personal data of the user(s).`}</p>
    <p>{`The temporary storage of the IP address by the system is necessary to enable delivery of the website to the user's device. For this purpose,
the IP address of the user(s) must remain stored for the duration of the session.`}</p>
    <p>{`The storage in log files is done to ensure the functionality of the website. In addition, we use the data to optimize the website
and to ensure the security of our information technology systems.`}</p>
    <p>{`The legal basis for the temporary storage of the data and the log files is our legitimate interest
in providing our website in accordance with Art. 6 para. 1 lit. f GDPR.`}</p>
    <p>{`The data is deleted as soon as it is no longer required to achieve the purpose for which it was collected.
In the case of the collection of data for the provision of the website, this is the case when the respective session has
ended. In the case of storage of data in log files, that will occur after ninety days at the latest.`}</p>
    <p>{`Since the collection of data for the provision of the website and the storage of the data in log files is absolutely
necessary for the operation of the website, there is no possibility of objection on the part of the user(s).`}</p>
    <h2>{`3. Contact via email`}</h2>
    <p>{`If you contact us via email, the information you provide in the email,
including the contact data you provide there, will be stored by us for the purpose of processing the request and in
the event of follow-up questions.`}</p>
    <p>{`The legal basis for the processing of the data is our legitimate interest in responding to your request pursuant
to Art. 6 para. 1 lit. f GDPR and, if applicable, Art. 6 para. 1 lit. b GDPR, if your request is aimed at concluding a contract.`}</p>
    <p>{`We store your data until you request us to delete it, you revoke your consent or the purpose for storing the data no longer applies.
Mandatory legal provisions - in particular legal retention periods - remain unaffected.`}</p>
    <p>{`The purpose of data storage ceases to apply when the respective conversation with the user has ended. The conversation is terminated
when the circumstances indicate that the matter in question has been conclusively clarified.`}</p>
    <p>{`You have the option to revoke your consent to the processing of personal data at any time. In this case, the processing of your request can not be continued.`}</p>
    <p>{`You can revoke your consent at any time by sending an email to `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{`.`}</p>
    <h2>{`4. Contact via WhatsApp`}</h2>
    <p>{`If you contact us via WhatsApp, the information you provide, including the contact data you provide there, will
be stored by WhatsApp Ireland Limited (4 Grand Canal Square Grand Canal Harbour Dublin 2 Irland)
for the purpose of processing the request and in the event of follow-up questions.`}</p>
    <p>{`The legal basis for the processing of the data is our legitimate interest in responding to your
request pursuant to Art. 6 para. 1 lit. f GDPR and, if applicable, Art. 6 para. 1 lit. b GDPR, if your
request is aimed at concluding a contract.`}</p>
    <p>{`You can find more information about WhatsApp's data processing `}<a parentName="p" {...{
        "href": "https://www.whatsapp.com/legal/#privacy-policy"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`5. Registration to receive an access code and Sendinblue`}</h2>
    <p>{`On our website there is the possibility to register to receive an access code, which is sent by email.`}</p>
    <p>{`We used Sendinblue to register and deliver the access code. The provider is Sendinblue SAS, 55 rue d'Amsterdam, 75008 Paris, France. Sendinblue is a service that can be used to organize and evaluate the newsletter dispatch. The email address you enter for the purpose of receiving the newsletter will be saved on Sendinblue's servers within Europe. In order to be able to verify the registration process in accordance with legal requirements, Sendinblue additionally stores the IP address of the calling device as well as the date and time of the registration and the confirmation of registration through the double opt-in procedure. In the double opt-in process, you will receive an email to the address you provided after registering for the access code to confirm the registration. Changes to the data stored with Sendinblue are also logged.`}</p>
    <p>{`The emails sent with Sendinblue allow us to analyze the behavior of email recipients ("success measurement") and to improve our content of the access code email.  For example, we can analyze how often recipients have opened the email message and how often which link in the email has been clicked. All links in the email are so-called tracking links, which can be used to count your clicks.`}</p>
    <p>{`There is a contract with Sendinblue, in which we oblige Sendinblue to protect the data of our customers and not to pass them on to third parties.`}</p>
    <p>{`The sending of the access code, the measurement of success, the use of the shipping service provider Sendinblue and the logging of the registration is based on your consent. Thus, the legal basis for the processing of data after your registration for the receipt of the access code is Art. 6 para. 1 lit. a GDPR.`}</p>
    <p>{`The data will be deleted as soon as they are no longer required to achieve the purpose for which they were collected. We store your email address until we have sent the access code to you. After that, your personal data will be deleted from Sendinblue.`}</p>
    <p>{`If you do not want Sendinblue to analyze your data or if you no longer want to receive the access code for other reasons, you must cancel the subscription to the access code. Unfortunately, a separate cancellation of the performance measurement is not possible. In this case, the entire subscription to the access code must be cancelled.`}</p>
    <p>{`The subscription to the access code can be cancelled by the affected user at any time. You can cancel the subscription to the access code by sending an email to `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{`.`}</p>
    <h2>{`6. Newsletter and Sendinblue`}</h2>
    <p>{`On our website there is the possibility to subscribe to a newsletter.`}</p>
    <p>{`We used Sendinblue for the registration and sending of newsletters. The provider is Sendinblue SAS, 55 rue d'Amsterdam, 75008 Paris, France. Sendinblue is a service with which the newsletter dispatch can be organized and evaluated. The email address you enter for the purpose of receiving the newsletter is stored on Sendinblue's servers within Europe. In order to be able to prove the registration process in accordance with legal requirements, Sendinblue additionally stores the IP address of the calling device as well as the date and time of the registration and the confirmation of registration by the double opt-in procedure. In the double opt-in process, you will receive an email to the specified address after registering for the newsletter to confirm the registration. Likewise, changes to the data stored with Sendinblue are logged.`}</p>
    <p>{`The newsletters sent with Sendinblue allow us to analyze the behavior of newsletter recipients ("success measurement") and to improve our newsletter. For example, we can analyze how often recipients have opened the newsletter message and how often which link in the newsletter has been clicked. All links in the email are so-called tracking links, with which your clicks can be counted.`}</p>
    <p>{`There is a contract with Sendinblue, in which we oblige Sendinblue to protect the data of our customers and not to pass them on to third parties.`}</p>
    <p>{`The newsletter dispatch, the measurement of success, the use of the dispatch service provider Sendinblue and the logging of the registration are based on your consent. Thus, the legal basis for the processing of data after your newsletter registration is Art. 6 para. 1 lit. a GDPR.`}</p>
    <p>{`The data will be deleted as soon as they are no longer required to achieve the purpose for which they were collected. Accordingly, the email address of the user(s) is stored as long as the subscription to the newsletter is active. If the subscription is cancelled, your personal data will be deleted from Sendinblue.`}</p>
    <p>{`If you do not wish to receive any analysis by Sendinblue or you no longer wish to receive the newsletter for other reasons, you must cancel the subscription to the newsletter. Unfortunately, a separate cancellation of the performance measurement is not possible. In this case, the entire newsletter subscription must be cancelled.`}</p>
    <p>{`The subscription to the newsletter can be cancelled by affected users at any time. For this purpose, a corresponding link can be found in each newsletter. In this case, the stored profile information will be deleted. You are also welcome to cancel your subscription by sending an email to `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{`.`}</p>
    <h2>{`7. Cookies`}</h2>
    <p>{`Our website uses cookies. Cookies are small text files that are stored on your device in your browser.
in your browser. They allow us to recognize your browser the next time
next time you visit. The cookies remain stored in your browser until you delete them yourself or until they are
delete them yourself or until an automatic deletion takes place.`}</p>
    <p>{`We use cookies to provide functions and services that are technically
technically necessary functions and
to analyze our website visits and to improve the website for you and other users.
improve the website for you and other users. You can revoke your consent at any time by clicking on "Revoke cookies" in the footer area
at the bottom of each page.`}</p>
    <p>{`We collect cookies in the following categories:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Necessary technical or functional cookies:`}</strong>{` These cookies are necessary for the operation of the website and allow, among other things, basic
Basic functions, such as saving cookie settings, among other things. The site cannot function without these
Cookies not function. The legal basis for the collection is Art. 6 para. 1 lit. f GDPR.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Statistic cookies:`}</strong>{` In order to improve our offer and our website, we collect anonymized
data for statistics and surveys. With the help of these cookies, we can, for example, determine the effect of
determine and optimize the effect of certain content on our website. Since we obtain your consent for this category of cookies, the legal basis is
for the collection is Art. 6 para. 1 lit. a GDPR. Details on the statistics cookies used are dealt with separately in this
privacy policy separately.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Marketing cookies:`}</strong>{` These cookies are used to display personalized content that matches your interests.
Thus, we can present you with particularly relevant offers. Since we obtain your consent for this category of cookies, the legal basis is Art.
for the collection is Art. 6 para. 1 lit. a GDPR. Details on the marketing cookies used are dealt with separately in this privacy policy.`}</p>
      </li>
    </ul>
    <h2>{`8. Google Analytics`}</h2>
    <p>{`We use functions of the web analysis service Google Analytics to analyze our website visits and to improve the website for you and other users. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland, parent company: Google LLC, 1600 Amphitheatre Parkway, Mountain View, California 94043 USA ("Google").`}</p>
    <p>{`Google Analytics uses cookies. Cookies are small text files that are stored on your device in your browser. They enable an analysis of your use of the website. The information generated by the cookie about your use of our website, as well as the following data, is usually transferred to Google servers in the USA and stored there.`}</p>
    <ul>
      <li parentName="ul">{`IP address`}</li>
      <li parentName="ul">{`name of the website visited, file, date and time of the request`}</li>
      <li parentName="ul">{`information about the browser type and version used`}</li>
      <li parentName="ul">{`operating system`}</li>
      <li parentName="ul">{`previously visited website (referrer URL)`}</li>
      <li parentName="ul">{`requesting provider`}</li>
      <li parentName="ul">{`amount of data transferred`}</li>
    </ul>
    <p>{`We use the IP anonymization function on this website. Thus, IP addresses are shortened by Google within member states of the EU or in other contracting states of the Agreement on the European Economic Area before being transmitted to the USA. This shortening eliminates the personal reference of your IP address. Under the terms of the commissioned data agreement which we have concluded with Google, the latter uses the information collected to evaluate website use and website activity and provides services associated with internet use.`}</p>
    <p>{`We use the "demographic characteristics" function of Google Analytics. Thus, reports can be created that contain statements about the age, gender and interests of website visitors. The data comes from interest-based advertising from Google as well as from data of the visitors from third-party providers. This data cannot be assigned to a specific person. You can deactivate this function at any time via the advertising settings in your Google account.`}</p>
    <p>{`The personal data of the users will be deleted or anonymized after 14 months.`}</p>
    <p>{`Google Analytics cookies belong to the category of statistics cookies. Since we obtain your consent for this category of cookies, the legal basis for the collection is Art. 6 para. 1 lit. a GDPR.`}</p>
    <p>{`You can revoke your consent to the use of Google Analytics at any time by clicking on "Revoke cookies" in the footer area at the bottom of each page. If you reject the collection of statistics cookies in the cookie settings, Google Analytics will not be used by us.`}</p>
    <p>{`You can find more information about Google's data processing `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`9. Facebook-Pixel`}</h2>
    <p>{`We use the Facebook pixel of the social network Facebook, which is operated by Facebook Inc, 1 Hacker Way, Menlo Park, CA 94025, USA, or if you are a resident of the EU, Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland ("Facebook").`}</p>
    <p>{`We use the Facebook pixel to display our ads placed on Facebook ("Facebook Ads") only to Facebook users who are interested in our online offer. In this way, we prevent users who are not interested from receiving advertisements.
The Facebook pixel allows us to determine you as the target group of our Facebook ads. Facebook can use the Facebook pixel to track your click behavior on our website and whether you are interested in our
content ("conversion" or "conversion measurement"). Thus, the effectiveness of the Facebook ads can be tracked. In addition to optimizing the ads we run, Facebook may also use the data for its own market
research and advertising purposes, as the data can be associated with your Facebook profile. We also use the remarketing function "Custom Audiences". This allows us to play ads for users who have already shown an interest in other ads from us.`}</p>
    <p>{`The cookies from Facebook Pixel belong to the category of marketing cookies. Since we obtain your consent for this category of cookies, the legal basis for the collection is Art. 6 para. 1 lit. a GDPR.`}</p>
    <p>{`You can revoke your consent to the use of Facebook Pixel at any time by clicking on "Revoke cookies" in the footer area at the bottom of each page. If you reject the collection of marketing cookies in the cookie settings, Facebook Pixel will not be used by us.`}</p>
    <p>{`If you have a Facebook account, you can find more information about setting usage-based advertising within Facebook `}<a parentName="p" {...{
        "href": "https://www.facebook.com/settings?tab=ads"
      }}>{`here`}</a>{`.
You can deactivate the remarketing function "Custom Audiences"
`}<a parentName="p" {...{
        "href": "https://www.facebook.com/ads/preferences/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`You can find more information about Facebook's data processing `}<a parentName="p" {...{
        "href": "https://www.facebook.com/about/privacy/"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`10. Other service providers and partners`}</h2>
    <p>{`We work with external partners who assist us in providing our services. When using external service providers (order processors), we ensure that data processing complies with German and European data protection standards through appropriate contracts with the service providers.`}</p>
    <p>{`The legal basis for the disclosure is Art. 28 GDPR.`}</p>
    <h3>{`10.1 Hosting`}</h3>
    <p>{`To provide our website and our backend systems, we work with external service providers, such
as Microsoft Azure from Microsoft Corporation (One Microsoft Way Redmond, WA 98052-6399 USA)
and Amazon Web Services, Inc. (410 Terry Avenue North Seattle WA 98109 United States).
The data is stored on servers within Europe.`}</p>
    <h3>{`10.2 Email provider`}</h3>
    <p>{`We manage emails we receive from you via `}<a parentName="p" {...{
        "href": "mailto:hello@vanatu.app"
      }}>{`hello@vanatu.app`}</a>{`, `}<a parentName="p" {...{
        "href": "mailto:support@vanatu.app"
      }}>{`support@vanatu.app`}</a>{` or `}<a parentName="p" {...{
        "href": "mailto:info@relayts.com"
      }}>{`info@relayts.com`}</a>{` using Microsoft Office 365 from Microsoft Corporation (One Microsoft Way Redmond, WA 98052-6399 USA). The data is stored on servers within Europe.`}</p>
    <h2>{`11. Your rights`}</h2>
    <p>{`We want you to be aware of your data protection rights (according to GDPR). We would like to inform you at this point:`}</p>
    <p><strong parentName="p">{`Right to object to data collection in specific cases`}</strong></p>
    <p>{`If the data processing is based on Art. 6 (1) lit. f GDPR, you have the right to object to the collection of the personal data concerned at any time for reasons based on your particular situation;
this also applies to profiling on the basis of these provisions. The respective legal bases on which processing is based you can find in this privacy policy.
If you object, we will no longer process the personal data concerned unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms, or for the establishment, exercise or defence of legal claims.`}</p>
    <p><strong parentName="p">{`Right to lodge a complaint with a supervisory authority`}</strong></p>
    <p>{`In the event of a breach of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority (Art. 77 GDPR). The right of appeal is without prejudice to other administrative or judicial remedies.`}</p>
    <p><strong parentName="p">{`Information, deletion and correction`}</strong></p>
    <p>{`You have the right to receive at any time, free of charge, information about your stored personal data (Art. 15 GDPR), its origin and recipient and the purpose of the data processing, as well as the right to have this data corrected or deleted (Art. 17 GDPR). You can contact us at any time at the address given at the beginning of this document with regard to this and other questions on the subject of personal data.`}</p>
    <p><strong parentName="p">{`Right to restriction of processing`}</strong></p>
    <p>{`You have the right to request the restriction of the processing of your personal data (Art. 18 GDPR). To do this, you can contact us at any time at the address given at the beginning of this document. The right to restriction of processing exists in the following cases:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the review, you have the right to request the restriction of the processing of your personal data.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If the processing of your personal data was / is unlawful, you can request the restriction of data processing instead of deletion.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If we no longer need your personal data, but you need it to exercise, defend or enforce legal claims, you have the right to request restriction of the processing of your personal data instead of deletion.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you have objected in accordance with Article 21(1) of the GDPR, a balance must be struck between your interests and ours. As long as it has not yet been determined whose interests prevail, you have the right to demand the restriction of the processing of your personal data.`}</p>
      </li>
    </ul>
    <p>{`If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.`}</p>
    <p><strong parentName="p">{`Right to data portability`}</strong></p>
    <p>{`You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to a third party in a common, machine-readable format (Art. 20 GDPR). If you request the direct transfer of the data to another controller, this will only be done insofar as it is technically feasible.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      